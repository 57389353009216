import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import {akitaDevtools} from '@datorama/akita';
import {environment} from './environments/environment';
import {enableProdMode} from '@angular/core';

akitaDevtools();

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
