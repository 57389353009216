import { Component, OnInit } from '@angular/core';
import { AppService } from './service/app.service';
import { trace, Performance } from '@angular/fire/performance';
import { TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(private appService: AppService,
              private performance: Performance,
              private ccService: NgcCookieConsentService,
              private translateService: TranslateService) { }

  ngOnInit(): void {
    const traceInstance = trace(this.performance, 'appComponentOnInit');
    traceInstance.start();

    this.appService.init();

    traceInstance.stop();

    this.translateService.setDefaultLang('en');
  }

}
