import { Injectable } from '@angular/core';
import { CategoriesService } from '../shared/services/categories/categories.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private categoriesService: CategoriesService) { }

  init() {
    this.categoriesService.init();
  }

}
